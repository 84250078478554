
import { httpPost } from '@/api';

import { ajaxLoading, erpConfirm, errorMessage, successMessage } from '@/utils/publicMethods';
import { defineComponent, ref } from 'vue';
import useFormData from './useFormData';
export default defineComponent({
  components: {},
  props: {
    /** 新增编辑表单状态 1新增 2编辑 */
    formType: {
      type: [Number],
      default: 1,
    },
    data: {
      type: [Object],
    },
  },
  emits: ['close'],
  setup(props, ctx) {
    const params: any = {};
    const { rules, formData, formRef, setFormData } = useFormData(params);
    const inputvalue = ref('');
    const numbervalue = ref('');
    let timer: number | null = null;
    const close = (status = false) => {
      ctx.emit('close', status);
    };
    const submitData = async () => {
      const params = {
        businessId: formData.id,
        version: formData.version,
        count: numbervalue.value,
      };
      ajaxLoading.lock();
      const res = await httpPost('/malicrm/business/copyBusiness', params);
      if (res.code === 200) {
        ajaxLoading.unLock();
        successMessage('复制成功');
        close(true);
      } else {
        ajaxLoading.unLock();
        errorMessage(res.message);
      }
    };
    const numberkey = (e) => {
      numbervalue.value = e.replace(/[^\d]/g, '');
      if (parseInt(numbervalue.value) > 200) {
        numbervalue.value = '';
      }
      if (parseInt(numbervalue.value) === 0) {
        numbervalue.value = '';
      }
    };
    const remoteMethod = (e) => {
      inputvalue.value = e.replace(/[^A-Z\d]/g, '');
      if (!e) {
        return '';
      }
      if (timer) {
        clearTimeout(timer);
      }
      timer = window.setTimeout(async () => {
        const params = {
          businessNo: e,
        };
        const res = await httpPost('/malicrm/business/queryByBusinessNo', params);
        if (res.code === 200) {
          formData.label = res.result.name;
          formData.id = res.result.id;
          formData.version = res.result.version;
          formData.createdtime = res.result.createdTime;
        } else {
          formData.label = '';
          formData.id = '';
          formData.version = '';
          formData.createdtime = '';
          errorMessage(res.message);
        }
      }, 300);
    };
    /** 提交表单 */
    const saveFormData = () => {
      erpConfirm('确定复制吗？').then(async () => {
        if (!inputvalue.value) {
          errorMessage('请先填写原商机编号');
          return '';
        }
        if (!numbervalue.value) {
          errorMessage('请先填写复制个数');
          return '';
        }
        const params = {
          businessNo: inputvalue.value,
        };
        const res = await httpPost('/malicrm/business/queryByBusinessNo', params);
        if (res.code === 200) {
          submitData();
        } else {
          errorMessage(res.message);
        }
      });
    };
    return {
      close,
      formData,
      saveFormData,
      setFormData,
      rules,
      formRef,
      remoteMethod,
      inputvalue,
      timer,
      numbervalue,
      numberkey,
    };
  },
});
