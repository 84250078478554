import { reactive, ref, toRefs } from 'vue';
type FormData = {
  lable: string;
  createdtime: string;
  id: string | number;
  version: string | number;
  [propName: string]: any;
};
export default (params) => {
  const formRef = ref(); // 表单校验对象使用
  const formData: FormData = reactive({
    lable: '',
    createdtime: '',
    id: '',
    version: '',
    ...params,
  });
  const customFormData = reactive({});
  const rules = reactive({});
  const setFormData = (paramsData: any) => {
    for (const key in formData) {
      if (Object.prototype.hasOwnProperty.call(paramsData, key)) {
        formData[key] = paramsData[key];
      }
    }
  };
  return { rules, formData, formRef, setFormData, ...toRefs(customFormData) };
};
