
import { defineComponent, ref, getCurrentInstance } from 'vue';
import CrmList from '@/components/CrmList/CrmList.vue';
import OpportunityForm from './components/OpportunityForm/OpportunityForm.vue';
import CopyOpportunityForm from './components/CopyOpportunityForm/index.vue';
import useListData from './components/useListData';
import useVisible from '@/hooks/useVisible';
import { ajaxLoading, loadPage, erpConfirm, errorMessage, exportFile } from '@/utils/publicMethods';
import useTimer from '@/hooks/useTimer';
import router from '@/router';
export default defineComponent({
  name: 'OpportunityList',
  components: { CrmList, OpportunityForm, CopyOpportunityForm },
  props: {},
  setup() {
    const instance = getCurrentInstance() as any;
    const { startTimer, timeStatus } = useTimer();
    const { filterOptions, columnList, tableRef } = useListData(instance);
    const { visible, showVisible, formType, setFormType } = useVisible();
    const rowData = ref({});
    const exportParams = ref({});
    const refreshTable = (status = false) => {
      tableRef.value.refreshTable(status);
    };
    //拦截处理自定义筛选参数
    const queryFn = (data: any) => {
      let params = {
        isContainsFour: 1,
      };
      if (data && data.orderStatus) {
        params.isContainsFour = data.orderStatus.includes(5) ? 2 : 1;
      }
      return params;
    };

    const rowClick = (row, column) => {
      if (column.label !== '客户') {
        loadPage('OpportunityDetail', {
          id: row.id,
          isRecycle: false,
          status: row.status,
          backFrom: '/opportunity/opportunityList',
        });
      }
    };
    const getParams = (e) => {
      exportParams.value = e;
    };

    const exportBusiness = () => {
      if (timeStatus.value === true) {
        erpConfirm('确定导出吗？').then(() => {
          startTimer();
          exportFile({
            type: 'POST',
            url: '/malicrm/business/exportBusiness',
            data: exportParams.value,
          });
        });
      } else {
        errorMessage('导出的点击间隔请勿小于30秒');
      }
    };

    // 打开新增弹窗
    const openDrawer = (formType: number): void => {
      setFormType(formType);
      showVisible();
    };
    const setRowData = (row) => {
      rowData.value = row;
    };
    const closeVisible = (status) => {
      visible.value = false;
      // 解除重复提交
      ajaxLoading.unLock();
      if (status) {
        refreshTable(status);
      }
    };
    const getLoadPage = (url) => {
      router.push(url);
    };
    return {
      exportBusiness,
      queryFn,
      filterOptions,
      columnList,
      tableRef,
      refreshTable,
      visible,
      formType,
      showVisible,
      closeVisible,
      setFormType,
      rowClick,
      getParams,
      exportParams,
      getLoadPage,
      rowData,
      setRowData,
      openDrawer,
    };
  },
});
